import { Entity, makeEntity } from './people'
import {
  AnalyticDetail, makeAnalyticDetail, Deposit, makeDeposit, Payment, makePayment, PaymentMode, makePaymentMode, Refund,
  makeRefund
} from '@/types/payments'

export class Expense {
  constructor(
    public id: number,
    public label: string,
    public amount: number,
    public createdOn: Date|null,
    public isRefund: boolean,
    public expenseDate: Date,
    public paymentMode: PaymentMode,
    public analytics: AnalyticDetail[],
    public isTransferBank: boolean,
    public bankName: string,
    public bankNumber: string,
    public emitter: string,
    public reference: string
  ) {
  }

  public getDate(): Date|null {
    return this.expenseDate ? this.expenseDate : this.createdOn
  }
}

export function makeExpense(jsonData: any = null): Expense {
  if (!jsonData) {
    jsonData = {}
  }
  const analytics = jsonData.analytics || []
  return new Expense(
    jsonData.id || 0,
    jsonData.label || '',
    (+jsonData.amount) || 0,
    jsonData.created_on || null,
    !!jsonData.is_refund,
    jsonData.expense_date || null,
    makePaymentMode(jsonData.payment_mode),
    analytics.map((elt: any) => makeAnalyticDetail(elt)),
    !!jsonData.transfer_bank,
    jsonData.bank_name || '',
    jsonData.bank_number || '',
    jsonData.emitter || '',
    jsonData.reference || ''
  )
}

export class CashLog {
  constructor(
    public id: number,
    public label: string,
    public isExpense: boolean,
    public isRefund: boolean,
    public isPayment: boolean,
    public hasDeposit: boolean,
    public deposit: Deposit|null,
    public amount: number,
    public logDate: Date,
    public analytics: AnalyticDetail[],
    public entity: Entity,
    public isTransferBank: boolean,
    public bankName: string,
    public bankNumber: string,
    public emitter: string,
    public reference: string,
    public payment: Payment|null,
    public expense: Expense|null,
    public refund: Refund|null,
    public createdOn: Date|null,
    public createdBy: string
  ) {
  }
}

export function makeCashLog(jsonData: any = null): CashLog {
  if (!jsonData) {
    jsonData = {}
  }
  const analytics = jsonData.analytics || []
  return new CashLog(
    jsonData.id || 0,
    jsonData.label || '',
    !!jsonData.is_expense,
    !!jsonData.is_refund,
    !!jsonData.is_payment,
    !!jsonData.has_deposit,
    jsonData.deposit ? makeDeposit(jsonData.deposit) : null,
    +jsonData.amount || 0,
    jsonData.log_date,
    analytics.map((elt: any) => makeAnalyticDetail(elt)),
    makeEntity(jsonData.entity),
    !!jsonData.is_transfer_bank,
    jsonData.bank_name || '',
    jsonData.bank_number || '',
    jsonData.emitter || '',
    jsonData.reference || '',
    jsonData.payment ? makePayment(jsonData.payment) : null,
    jsonData.expense ? makeExpense(jsonData.expense) : null,
    jsonData.refund ? makeRefund(jsonData.refund) : null,
    jsonData.created_on || null,
    jsonData.created_by || ''
  )
}
