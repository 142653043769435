<template>
  <span class="cash-modal" v-if="hasPerm('cash.add_cashlog')">
    <b-modal
      id="bv-modal-transfer-cash"
      cancel-title="Annuler"
      :ok-disabled="isTransferDisabled()"
      @ok.prevent="onTransfer"
      ok-variant="primary"
      ok-title="Transférer"
      @show="onShow"
      size="xl"
    >
      <template v-slot:modal-title>
        <b>
          <i class="fa fa-piggy-bank"></i>
          Transfert de caisse
        </b>
      </template>
      <div v-if="errorText" class="error-text">
        <i class="fa fa-error"></i> {{ errorText }}
      </div>
      <b-row>
        <b-col>
          <b-form-group
            id="payment-mode1-group"
            label="Origine"
            label-for="payment-mode1"
            :disabled="allPaymentModes.length < 2"
          >
            <b-form-select v-model="selectedPaymentMode1" id="payment-mode1">
              <b-select-option v-for="elt of allPaymentModes" :key="elt.id" :value="elt">
                {{ elt.name }}
              </b-select-option>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            id="payment-mode2-group"
            label="Destination"
            label-for="payment-mode2"
            :disabled="allPaymentModes.length < 2"
          >
            <b-form-select v-model="selectedPaymentMode2" id="payment-mode2">
              <b-select-option v-for="elt of allPaymentModes" :key="elt.id" :value="elt">
                {{ elt.name }}
              </b-select-option>
            </b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            id="comments-group"
            label="Libellé"
            label-for="label"
          >
            <b-form-input id="label" v-model="label" required></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="2">
          <b-form-group
            label="Montant en €"
            label-for="amount"
            required
          >
            <decimal-input id="amount" v-model="amount">
            </decimal-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Compte général"
            label-for="'general-account'"
          >
            <b-form-select v-model="generalAccount" id="general-account'">
              <b-select-option v-for="elt of generalAccounts" :key="elt.id" :value="elt">
                {{ elt.name }}<span v-if="elt.label"> - {{ elt.label }}</span>
              </b-select-option>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group
            id="date-group"
            label="Date"
            label-for="date"
            description=""
          >
            <b-form-input type="date" id="date" v-model="date" @change="onDateChanged">
            </b-form-input>
            <div v-if="dateError" class="cash-date-error">
              {{ dateError }}
            </div>
            <div v-if="dateWarning" class="cash-date-warning">
              {{ dateWarning }}
            </div>
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group
            id="date-group"
            label="Heure"
            label-for="time"
            description=""
          >
            <b-form-input type="time" id="time" v-model="time">
            </b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
  </span>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>
import moment from 'moment'
import { mapActions } from 'vuex'
import DecimalInput from '@/components/Controls/DecimalInput'
import { currency } from '@/filters/texts'
import { BackendMixin } from '@/mixins/backend'
import { makeAnalyticAccount, makeGeneralAccount } from '@/types/payments'
import { BackendApi } from '@/utils/http'
import { sum } from '@/utils/math'

export default {
  name: 'transfer-cash-modal',
  components: { DecimalInput, },
  mixins: [BackendMixin],
  props: {
    allAnalyticAccounts: Array,
    allGeneralAccounts: Array,
    allPaymentModes: Array,
  },
  data() {
    return {
      label: '',
      errorText: '',
      selectedPaymentMode1: null,
      selectedPaymentMode2: null,
      date: '',
      time: '',
      amount: 0,
      generalAccount: null,
    }
  },
  computed: {
    dateError() {
      const now = moment()
      if (!this.date) {
        return 'Date manquante'
      }
      const duration = moment.duration(now.diff(moment(this.date)))
      if (duration.asDays() < 0) {
        return 'La date ne peut pas être future'
      }
      return ''
    },
    dateWarning() {
      const now = moment()
      if (this.date) {
        const duration = moment.duration(now.diff(moment(this.date)))
        if (duration.asDays() > 30) {
          return 'La date est très antérieure à la date du jour. Vérifier qu\'il n\'y a pas d\'erreur.'
        }
      }
      return ''
    },
    generalAccounts() {
      const transferGeneralAccounts = this.allGeneralAccounts.filter(elt => elt.isCashTransfer)
      if (transferGeneralAccounts.length > 0) {
        return transferGeneralAccounts
      }
      return this.allGeneralAccounts
    },
  },
  mounted() {
    this.label = ''
  },
  watch: {
    selectedPaymentMode1() {
      this.setLabel()
    },
    selectedPaymentMode2() {
      this.setLabel()
    },
    generalAccounts() {
      if (this.generalAccounts.length === 1) {
        this.generalAccount = this.generalAccounts[0]
      }
    },
  },
  methods: {
    ...mapActions(['addSuccess']),
    setLabel() {
      if (this.selectedPaymentMode1 && this.selectedPaymentMode2) {
        this.label = 'Transfert ' + this.selectedPaymentMode1.name
        this.label += ' vers ' + this.selectedPaymentMode2.name
      }
    },
    isTransferDisabled() {
      let disabled = (this.amount === 0) || (!this.label) || (!this.date)
      if (!disabled && (!this.selectedPaymentMode1 || !this.selectedPaymentMode2)) {
        disabled = true
      }
      if (!disabled && (this.selectedPaymentMode1.id === this.selectedPaymentMode2.id)) {
        disabled = true
      }
      if (!disabled && !this.generalAccount) {
        disabled = true
      }
      if (!disabled && this.dateError) {
        disabled = true
      }
      return disabled
    },
    async onTransfer() {
      this.errorText = ''
      try {
        const url = '/api/cash-logs/transfer/'
        const backendApi = new BackendApi('post', url)
        const time = this.time ? (this.time + ':00') : '00:00:00'
        let data = {
          label: this.label,
          date: moment(this.date).format('YYYY-MM-DD') + 'T' + time,
          payment_mode_from: this.selectedPaymentMode1.id,
          payment_mode_to: this.selectedPaymentMode2.id,
          amount: this.amount,
          general_account: this.generalAccount ? this.generalAccount.id : 0,
          // analytic_account: line.analyticAccount.id,
        }
        await backendApi.callApi(data)
        this.$bvModal.hide('bv-modal-transfer-cash')
        this.$emit('done')
      } catch (err) {
        this.errorText = this.getErrorText(err)
      }
    },
    onDateChanged() {
      this.time = null
    },
    onShow() {
      this.date = moment().format('YYYY-MM-DD')
      this.time = moment().format('HH:mm')
      this.selectedPaymentMode1 = null
      this.selectedPaymentMode2 = null
      this.label = 'Transfert de caisse'
      this.amount = 0
      if (this.generalAccounts.length === 1) {
        this.generalAccount = this.generalAccounts[0]
      }
    },
  },
}
</script>
<style scoped lang="less">
.inline {
  display: inline-block;
  margin-right: 10px;
}
.cash-date-error {
  font-size: 12px;
  padding: 5px 3px;
  background: #f78888;
}
.cash-date-warning {
  font-size: 12px;
  padding: 5px 3px;
  background: #efcb64;
}
</style>
